"use client";
import { TrackingData } from "@growthbook/growthbook";
import { useEffect } from "react";
import { onExperimentView } from "utils/growthbook";

export const GrowthbookTracking = ({ data }: { data: TrackingData[] }) => {
  useEffect(() => {
    data.forEach(({ experiment, result }) => {
      onExperimentView(experiment, result);
    });
  }, [data]);

  return null;
};
